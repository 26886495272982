
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "微软雅黑";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.list {
  height: 50px;
  background-color: #ffffff !important;
}

.left {
  margin-left: 20px !important;
  p {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #000 !important;
  }
  font-size: 16px !important;
  font-weight: 700px;
  color: #000;
}

.row {
  background-color: #f2f2f2 !important;
}

// .text {
//   background-color: #f2f2f2 !important;
// }

.cont {
  background-color: #f2f2f2 !important;
}

.anniu {
  background: #006eff;
  font-size: 12px;
  height: 30px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.page-top-right {
  font-size: 12px;
  margin-right: 25px !important;
}

.page-all {
  padding-left: 0 !important;
  div {
    background-color: white;
    div {
      padding-left: 4px !important;
    }
  }
}

.table {
  margin-top: 20px !important;
  background-color: #ffffff;
  margin: 20px !important;
}

::-webkit-scrollbar {
  display: none;
}

.table-a {
  margin: 20px !important;
  background-color: #ffffff;
}

.add {
  background-color: #006eff !important;
  border: none !important;
  font-size: 14px;
  color: white !important;
  margin: 20px !important;
}

.image {
  height: 36px !important;
  width: 175px !important;
  margin: 10px 0 0 10px;
}

.table:first-child {
  line-height: 48px !important;
  font-size: 12px !important;
  font-weight: 700;
  color: #888888;
}

.el-row:hover {
  background-color: #f7f7f7f7 !important;
}

.add:hover {
  background-color: #0063e5 !important;
}
.lable {
  background: #ffffff !important;
}

.el-menu-item {
  padding: 0 20px 0 20px !important;
}

.el-menu-item-group {
  ul {
    a {
      height: 38px !important;
      font-size: 14px !important;
      text-align: left !important;
    }
  }
}

.page-top-left {
  div {
    span {
      color: #c1c6c8 !important;
    }
  }
}

.spn {
  color: #00a4ff !important;
}

.page-top {
  background-color: #1e222d;
  height: 50px;
}
